<template>
  <div v-if="cntSettings">
    <div v-for="setting in settings" :key="setting.code">
      <label><input name="setting" :disabled="is_block(setting.code)" type="checkbox" :value="setting.code" v-model="selSetting"> {{ $t('billmanager.billmanagersetting.'+setting.code) }}</label>
    </div>

    <div>
      <label><input name="show_nearest_event" type="checkbox" v-model="show_nearest_event"> {{ $t('billmanager.billmanagersetting.show_nearest_event') }}</label>
    </div>

    <div>
      <label><input name="preview_auto_payout" type="checkbox" v-model="preview_auto_payout"> {{ $t('billmanager.billmanagersetting.preview_auto_payout') }}</label>
    </div>

    <button v-if="!isMainBill" @click="save" class="btn btn-sm btn-success m-1">{{ $t('billmanager.billmanagersetting.save') }}</button>
  </div>
</template>

<script setup>
import BillService from "../../services/bill.service";
import {ref, onDeactivated, inject, computed} from "vue";
import {useStore} from "vuex";

const store = useStore()

const set_result = inject('set_result')

onDeactivated(() => {
  set_result('off')
})
const is_admin_sb = inject('is_admin_sb')

const isMainBill = inject('isMainBill')
const currentUser = inject('currentUser')

const settings = ref([])
const selSetting = ref([])
const sel_bill = ref([])

const show_nearest_event = ref(true)
const preview_auto_payout = ref(true)

function getSettings(selBill)
{
  if(selBill!=undefined) {
    sel_bill.value=selBill
    BillService.getSettings(selBill).then(
      (response) => {
        settings.value = response.data.settings;

        selSetting.value = []
        for(let key in settings.value) {
          let cur=settings.value[key]
          if(cur.is_set=='1') {
            selSetting.value.push(cur.code)
          }
        }

        show_nearest_event.value=response.data.show_nearest_event
        preview_auto_payout.value=response.data.preview_auto_payout
      },
      (error) => {
        set_result(error.response.status, error)
      }
    )
  }
}

function save()
{
  BillService.saveSettings(sel_bill.value, selSetting.value, show_nearest_event.value, preview_auto_payout.value).then(
    () => {
      set_result()
    },
    (error) => {
      set_result(error.response.status, error)
    }
  )
}

function is_block(cur_sett)
{
  let res=false
  if(currentUser.value['roles'].includes('super') && isMainBill.value==false) {
    return res
  }

  if(isMainBill.value) {
    res=true
    return res
  }

  if(userGroups.value.includes(cur_sett) === false) {
    res=true
  }

  if(cur_sett == 'bill_enable_manual_bet_sum' && is_admin_sb ) {
    res=false
  }

  return res
}

function cntSettingsFn()
{
  let selBills=store.state.bill.selBills
  let cnt = selBills.length

  if(cnt==1)
  {
    getSettings(selBills[0])
  }

  return cnt
}
const cntSettings = computed(cntSettingsFn)

function userGroupsFn()
{
  return currentUser.value.bill_groups
}
const userGroups = computed(userGroupsFn)
</script>
