<template>
  <VueGoodTable
    :columns="columns"
    :rows="rows"
    :lineNumbers="true"
    :pagination-options="{
          enabled: true,
          perPage: 20,
          perPageDropdown: perPageDropdown,
          nextLabel: $t('app.vue-good-table-next.nextLabel'),
          prevLabel: $t('app.vue-good-table-next.prevLabel'),
          rowsPerPageLabel: $t('app.vue-good-table-next.rowsPerPageLabel'),
          ofLabel: $t('app.vue-good-table-next.ofLabel'),
          pageLabel: $t('app.vue-good-table-next.pageLabel'), // for 'pages' mode
          allLabel: $t('app.vue-good-table-next.allLabel')
        }"
        compactMode>
  </VueGoodTable>
</template>

<script setup>
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {VueGoodTable} from "vue-good-table-next";
import BillService from "../../services/bill.service";
import {ref, onDeactivated, inject, computed, onActivated, watch, reactive} from "vue";
import {useStore} from "vuex";

const $t = inject('$t')

const store = useStore()

const set_result = inject('set_result')

onDeactivated(() => {
  set_result('off')
})

const isMainBill = inject('isMainBill')
const currentUser = inject('currentUser')
const SelOneBill = inject('SelOneBill')

const settings = ref([])
const selSetting = ref([])

const columns = computed(() => [
  {
    label: $t('billmanager.billmanagerhistory.date'),
    field: 'event_date_char'
  },
  {
    label: $t('billmanager.billmanagerhistory.event'),
    field: 'event_type'
  },
  {
    label: $t('billmanager.billmanagerhistory.user'),
    field: 'user_login'
  }
])

const rows = ref([])

function getHistory(selBill)
{
  if(selBill!=undefined) {
    rows.value = []
    BillService.getHistory(selBill).then(
      (response) => {
        let cur = []
        for(let key in response.data) {
          cur = response.data[key]

          switch (cur['event_type']) {
            case 'user_create':
              cur['event_type'] = $t('billmanager.billmanagerhistory.' + cur['event_type']) + ' ' + cur['event_description']
            break;
            case 'user_active':
            case 'user_blocked':
              cur['event_type'] = $t('billmanager.billmanagerhistory.' + cur['event_type']) + ' ' + cur['event_description']
              break;
            case 'wizards_games_bills_change':
            case 'wizards_game_bills_add':
            case 'wizards_game_bills_del':
            case 'change_bill_game':
              var desc_event=$t('billmanager.billmanagerhistory.'+cur['event_type'])+' '

              var json_data = JSON.parse(cur['event_description'])

              if(cur['event_type']=='change_bill_game' && json_data!==null) {
                desc_event=''
                for (let key in json_data) {
                  let cur=json_data[key]
                  desc_event+='"'
                  desc_event+=$t('billmanager.billmanagerhistory.change_bill_game_' + cur.isselect)
                  desc_event+=': '
                  desc_event+=cur.game_name
                  desc_event+='"; '
                }
              }

              cur['event_type'] = desc_event
              break;
            case 'jeckpot_add':
            case 'jeckpot_edit':
              cur['event_type'] = cur['event_type'] + ' ' + cur['event_description']
              break;
            case 'payout_by_admin':
            case 'table_clear_statistic':
            case 'invisible_bets':
            case 'receipt_apply':
            case 'change_bets_koef':
            case 'clear_static':
              cur['event_type'] = $t('billmanager.billmanagerhistory.' + cur['event_type'])+ ' ' + cur['event_description']
              break;
            case 'password_change':
              cur['event_type'] = $t('billmanager.billmanagerhistory.' + cur['event_type']) + ' ' + cur['event_description']
              break;
            case 'change_parent':
              cur ['event_type'] = $t('billmanager.billmanagerhistory.' + cur['event_type']) + ' ' + cur['event_description']
              break;
            default:
              cur['event_type'] = $t('billmanager.billmanagerhistory.' + cur['event_type'])
          }

          rows.value.push(cur)
        }
      },
      (error) => {
        set_result(error.response.status, error)
      }
    )
  }
}

function selCurrencyWatch()
{
  if(SelOneBill.value!=='') {
    getHistory(SelOneBill.value)
  }
}
watch(SelOneBill, selCurrencyWatch)

onActivated(() => {
  getHistory(SelOneBill.value)
})
</script>
