<template>
  <el-table
      ref="tableEl"
      :data="data"
      style="width: 100%"
      border
      :scrollbar-always-on="true"
      :cell-class-name="cellStyleClassFnEl"
  >
    <el-table-column v-for="column in columnsTable" :key="column.field" :prop="column.field" :label="column.label" :min-width="column.minwidth">
      <template v-if="column.field=='action'" #default="props">
        <span class="cursor-pointer" @click="clearStatistic(props.row.bill_id, props.row.game_id)">
          <font-awesome-icon icon="trash" />
        </span>
      </template>
    </el-table-column>
  </el-table>
</template>

<script setup>
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {VueGoodTable} from "vue-good-table-next";
import BillService from "../../services/bill.service";
import {ref, onDeactivated, inject, computed, onActivated, watch, reactive} from "vue";
import {useStore} from "vuex";

const $t = inject('$t')

const store = useStore()

const set_result = inject('set_result')

const data = ref([])

function fieldsTable()
{
  let fld = []

  fld.push({label: $t('game.games.nameid'), field: 'nameid'})
  fld.push({label: $t('statistic.deposit'), field: 'deposit'})
  fld.push({label: $t('statistic.withdraw'), field: 'withdraw'})
  fld.push({label: $t('statistic.profit'), field: 'profit_ch'})
  fld.push({label: $t('statistic.recoil_ratio'), field: 'recoil_ratio'})
  fld.push({label: $t('statistic.stat_gather'), field: 'stat_gather'})
  fld.push({label: $t('app.action'), field: 'action'})

  return fld
}
const columnsTable = computed(fieldsTable)

onDeactivated(() => {
  set_result('off')
})

const isMainBill = inject('isMainBill')
const currentUser = inject('currentUser')
const SelOneBill = inject('SelOneBill')

const settings = ref([])
const selSetting = ref([])

function getStatistic(selBill)
{
  if(selBill!=undefined) {
    BillService.getStatistic(selBill).then(
      (response) => {
        data.value=response.data
      },
      (error) => {
        set_result(error.response.status, error)
      }
    )
  }
}

function clearStatistic(selBill, selGame, selGameName)
{
  if(selBill!=undefined && selGame!=undefined) {
    let result = confirm($t('app.are_you_sure'))

    if(result==true) {
      BillService.clearStatistic(selBill, selGame).then(
        () => {
          getStatistic(SelOneBill.value)
        },
        (error) => {
          set_result(error.response.status, error)
        }
      )
    }
  }
}

function cellStyleClassFnEl(data)
{
  let resclass=''

  if(data.row.level>0 && data.row.empty_lv==undefined) {
    if (data.row.level % 2 == 0) {
      resclass = 'bgcolor_gray2'
    } else {
      resclass = 'bgcolor_gray1'
    }
  }

  if(data.row.profit>0) {
    resclass='bgcolor_up'
  }

  if(data.row.profit<0) {
    resclass='bgcolor_down'
  }

  if(data.row.profit==0) {
    resclass='bg-white'
  }

  return resclass
}

function selCurrencyWatch()
{
  if(SelOneBill.value!=='') {
    getStatistic(SelOneBill.value)
  }
}
watch(SelOneBill, selCurrencyWatch)

onActivated(() => {
  getStatistic(SelOneBill.value)
})
</script>
