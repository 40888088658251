<template>
  <div>
    <Bill v-if="type=='edit_save'" :key="bill_key" @selRow="onSelBill" @onGetRows="updBills" :selRow="sel_bill" :type="type" next_type="edit_save"></Bill>
    <BillBlock v-if="type=='unblock'" @selRow="onSelBill" :selRow="sel_bill" next_type="edit_save"></BillBlock>
  </div>
</template>

<script setup>
import {inject, onActivated, onMounted, provide, ref, watchEffect} from "vue";
import {useStore} from "vuex";
import Bill from "@/components/Bill/Bill.vue";
import BillBlock from "@/components/Bill/BillBlock.vue";
import BillService from "../../services/bill.service";

const $t = inject('$t')
const set_result = inject('set_result')

const store = useStore()

const selOneBill = inject('selOneBill')

const bill_key = ref(0)
const first_tbill = ref(null)

const type = ref('edit_save')
const is_bill = ref(false)
const sel_bill = ref({})

function onSelBill(params)
{
  sel_bill.value=params.sel_bill
  is_bill.value=params.is_sel
  type.value=params.type
}

function getBillsTree()
{
  store.dispatch('bill/updBills')
}

function updBills()
{
  // getBills()
  getBillsTree()
}
provide('updBills', updBills)

function rowSetActiveStatus(id, status)
{
  BillService.setActiveStatus(id, status).then(
      () => {
        updBills()
      },
      (error) => {
        let nam_err=''
        let err_data=error.response.data.err_info
        if(err_data.indexOf('###upstream_blocking###')!=-1) {
          //let result = err_data.match(/[#][#][#](.*)[#][#][#]/);
          nam_err=$t('bill.billactions.upstream_blocking')
        }

        set_result(error.response.status, error, nam_err)
      }
  )
}
provide('rowSetActiveStatus', rowSetActiveStatus)

function changeBill()
{
  if(sel_bill.value.id==undefined) {
    sel_bill.value.id = selOneBill.value
  }

  if(first_tbill.value!=selOneBill.value) {
    sel_bill.value.id = selOneBill.value
    first_tbill.value = sel_bill.value.id
    bill_key.value++
    type.value='edit_save'
  }
}
watchEffect(changeBill)

function activated_fn()
{
  bill_key.value++
}
onActivated(activated_fn)
</script>
