<template>
  <span>{{ $t('billmanager.billmanagerjackpots.selectGame') }}</span><Select2 v-model="game_sel" :disabled="game_block" :options="games_list" :settings="{ width: '100%' }" />
  <p></p>
  <div v-if="is_select_bill_game">
    <button v-if="isaadj==false" @click="addJackpots(true)" class="btn-success">{{ $t('billmanager.billmanagerjackpots.addJackpots') }}</button>
    <div v-if="isaadj==true">
      <BillManagerJackpotsEdit></BillManagerJackpotsEdit>
    </div>
  </div>
</template>

<script setup>
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {VueGoodTable} from "vue-good-table-next";
import BillService from "../../services/bill.service";
import {ref, onDeactivated, inject, computed, onActivated, watch, reactive, watchEffect, provide} from "vue";
import {useStore} from "vuex";
import GameService from "../../services/game.service";
import BillManagerJackpotsEdit from "../Jackpots/JackpotsEdit.vue";

const $t = inject('$t')

const store = useStore()

const set_result = inject('set_result')

const isMainBill = inject('isMainBill')
const currentUser = inject('currentUser')
const SelOneBill = inject('SelOneBill')

const games_list = ref([])
const game_sel = ref('')
const game_block = ref(false)

const isaadj = ref(false)

function getSelectedGame()
{
  if(SelOneBill.value!=undefined) {
    game_block.value=true
    GameService.getGamesByBill(SelOneBill.value).then(
        (response) => {
          game_sel.value=''
          games_list.value=[]
          for(let key in response.data) {
            let cur = response.data[key]
            if(cur.isselect=='1') {
              games_list.value.push({id: cur.id, text: cur.game_name})
            }
          }
          game_block.value=false
        },
        (error) => {
          set_result(error.response.status, error)
        }
    )
  }
}
watchEffect(getSelectedGame)

function select_bill_game()
{
  let res = false

  if (SelOneBill!==undefined && SelOneBill!=='' && game_sel.value!=='') {
    res = true
  }

  return res
}
const is_select_bill_game = computed(select_bill_game)

function addJackpots(aj)
{
  isaadj.value=aj
}
provide('addJackpots', addJackpots)

onActivated(() => {
  getSelectedGame()
})
</script>
