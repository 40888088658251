<template>
  <p></p>
  <div v-if="cntSelBill!=1" class="container-fluid">{{ $t('billmanager.billmanager.chooseOneAccount') }}</div>
  <div class="container-fluid" v-if="cntSelBill==1">
    <ul class="nav nav-tabs">
      <li class="nav-item cursor-pointer btn m-0 p-0"
          v-for="tab in tabs"
          :key="tab.code"
          :class="['tab-button', { active: currentTab === tab.code }]"
          @click="setCurrentTab(tab.code)">
        <a class="nav-link" :class="['tab-button', { active: currentTab === tab.code }]">{{ tab.text }}</a>
      </li>
    </ul>
    <div class="p-2">{{}}
      <keep-alive>
        <component :is="currentTabComponent" :selRow="sel_bill" :type="'edit'"></component>
      </keep-alive>
    </div>
  </div>
</template>

<script setup>
import {inject, ref, computed, provide, onMounted, watchEffect, defineEmits} from "vue";
import BillManagerBalans from "./BillManagerBalans.vue";
import BillManagerSetting from "./BillManagerSetting.vue";
import BillManagerHistory from "./BillManagerHistory.vue";
import BillManagerStatistic from "./BillManagerStatistic.vue";
import BillManagerJackpots from "./BillManagerJackpots.vue";
import BillManagerParams from "./BillManagerParams.vue";
import BillService from "@/services/bill.service";

const $t = inject('$t')

const selOneBillInfo = inject('selOneBillInfo')

const currentTab = ref('Balans')

function tabs_comp() {
  let res = [
      {"code": "Balans", "text": $t('billmanager.billmanager.balans')},
      {"code": "Setting", "text": $t('billmanager.billmanager.setting')}
  ]

  if(is_super) {
    res = [
      {"code": "Balans", "text": $t('billmanager.billmanager.balans')},
      {"code": "Setting", "text": $t('billmanager.billmanager.setting')},
      {"code": "Params", "text": $t('billmanager.billmanager.params')},
      {"code": "History", "text": $t('billmanager.billmanager.history')},
      {"code": "Statistic", "text": $t('billmanager.billmanager.statistic')}
    ]
  }

  return res
}
const tabs = computed(tabs_comp)

function setCurrentTab(tab_code) {
  currentTab.value=tab_code
}

const currentTabComponent = computed(function ()
{
  let curComp = BillManagerBalans
  if(currentTab.value=='Balans') {
    curComp = BillManagerBalans
  }

  if(currentTab.value=='Setting') {
    curComp = BillManagerSetting
  }

  if(currentTab.value=='Params') {
    curComp = BillManagerParams
  }

  if(currentTab.value=='History') {
    curComp = BillManagerHistory
  }

  if(currentTab.value=='Statistic') {
    curComp = BillManagerStatistic
  }

  if(currentTab.value=='Jackpots') {
    curComp = BillManagerJackpots
  }

  return curComp
})

const set_result = inject('set_result')
const cntSelBill = inject('cntSelBill')
const isMainBill = inject('isMainBill')
const is_super = inject('is_super')
const SelOneBill = inject('SelOneBill')

//const type = ref('edit')

const fromBM = ref('true')
provide('fromBM', fromBM)

const sel_bill = ref({})
const currentUser = inject('currentUser')
watchEffect(() => {
  sel_bill.value = selOneBillInfo.value
})

function calculateChildCount(parentId, billData)
{
  let childCount = 0;
  for (let key in billData) {
    let cur = billData[key];
    if (cur.parent_id === parentId) {
      childCount++;
    }
  }
  return childCount;
}
provide('calculateChildCount', calculateChildCount)

onMounted(() =>
{
  calculateChildCount()
})
</script>
